<template>
  <div class="Minimaering">
    <div class="Minima_div clearfix">
      <div class="zui_left fl">
        <div class="zui_left_div">
          <p class="le_p">草图</p>
          <div class="le_img">
            <span class="li_span_img" v-if="!reimg">
              <i class="el-icon-zoom-in" @click="dk_Dt()"></i>
            </span>
            <el-carousel>
              <el-carousel-item
                v-for="(items, indexs6) in imageList"
                :key="indexs6"
              >
                <img :src="items.value" alt="" />
              </el-carousel-item>
            </el-carousel>

            <div v-if="reimg">
              <el-upload
                class="upload-demo"
                drag
                :action="this.$store.state.uploads"
                accept=".png,.jpg,.bmp,.jpeg"
                :show-file-list="false"
                :on-success="handleVideoSuccessImg"
                :on-progress="handlePreviewImg"
                :before-upload="beforeAvatarUploadImg"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" style="margin-top: 0px">
                  只能上传图片文件
                </div>
              </el-upload>
            </div>
          </div>

          <div class="button_div">
            <el-button
              type="primary"
              :disabled="!leader"
              plain
              size="small"
              @click="reImgsss()"
              >{{ reimgType ? "重新上传" : "取消" }}
            </el-button>
          </div>
        </div>
        <div class="zui_left_div">
          <p class="le_p">推演测试</p>
          <div class="le_img">
            <video controls v-if="!reVideo">
              <source :src="ruleForm.p8.value" type="video/mp4" />
              您的浏览器不支持 video 标签。
            </video>

            <div v-if="reVideo">
              <el-upload
                class="upload-demo"
                drag
                :action="this.$store.state.uploads"
                accept=".avi, .mp4,"
                :show-file-list="false"
                :on-success="handleVideoSuccess"
                :on-progress="handlePreview"
                :before-upload="beforeAvatarUpload"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" style="margin-top: 0px">
                  只能上传avi/mp4文件
                </div>
              </el-upload>
            </div>
          </div>

          <div class="button_div">
            <el-button
              type="primary"
              :disabled="!leader"
              plain
              size="small"
              @click="emptys"
              >{{ reVideoType ? "重新上传" : "取消" }}
            </el-button>
          </div>
        </div>
      </div>
      <div class="zui_right fr" style="position: relative;">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          label-width="120px"
          size="small"
          @submit.native.prevent
        >
          <el-form-item label="现状：" prop="name">
            <el-col :span="23">
              <el-input
                type="textarea"
                :disabled="!leader"
                v-model="ruleForm.p2.value"
                @blur = "p2"
              ></el-input>
            </el-col>
            <!-- <el-col :span="11">
                                <el-input v-model="ruleForm.name" disabled></el-input>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:5px">

                                </div>
                            </el-col>
                            <el-col :span="11">
                                <el-input v-model="ruleForm.name" disabled></el-input>
                            </el-col> -->
          </el-form-item>
          <el-form-item label="问题：" prop="name">
            <el-col :span="23">
              <el-input
                type="textarea"
                v-model="ruleForm.p3.value"
                :disabled="!leader"
                @blur="p3"
              ></el-input>
            </el-col>
            <!-- <el-col :span="11">
                                <el-input v-model="ruleForm.name" disabled></el-input>
                            </el-col>
                            <el-col :span="1">
                                <div style="width:2px;height:5px">

                                </div>
                            </el-col>
                            <el-col :span="11">
                                <el-input v-model="ruleForm.name" disabled></el-input>
                            </el-col> -->
          </el-form-item>
          <el-form-item label="创意（想法）：" :disabled="!leader" prop="name">
            <el-col :span="23">
              <el-input type="textarea" v-model="ruleForm.p4.value" @blur="p4"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="创新（想法）：" prop="name">
            <el-col :span="23">
              <div class="zui_three">
                <ul class="zui_three_ul">
                  <li
                    class="zui_three_ul_li"
                    v-for="(item, index) in ruleForm.p5.value"
                    :key="index"
                  >
                    <div class="zui_three_div clearfix">
                      <span class="fl">热度为{{ item.level }}颗星的：</span>
                      <span class="fl">
                        <el-rate
                          v-model="item.level"
                          disabled
                          :colors="['#ff9900', '#ff9900', '#ff9900']"
                        >
                        </el-rate>
                      </span>
                    </div>
                    <ul class="icon_ul clearfix">
                      <li
                        class="zui_ul_li_item fl"
                        v-for="(items3, indesx3) in item.values"
                        :key="indesx3"
                      >
                        {{ items3.name }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- <el-input type="textarea" v-model="ruleForm.p5.value" disabled></el-input> -->
            </el-col>
          </el-form-item>
          <el-form-item label="方案（适应）：" prop="name">
            <el-col :span="23">
              <el-input
                type="textarea"
                :disabled="!leader"
                v-model="ruleForm.p6.value"
                @blur="p6"
              ></el-input>
            </el-col>
          </el-form-item>
          <p style="font-size: 14px; line-height: 40px; padding-left: 10px">
            行动（分工）：
          </p>

          <el-col :span="1">
            <div style="width: 2px; height: 5px"></div>
          </el-col>
          <el-col :span="11">
            <el-form-item
              :label="ruleForm.p7.value.role1.title"
              label-width="80px"
              prop="name"
            >
              <el-input
                v-model="ruleForm.p7.value.role1.name"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              :label="ruleForm.p7.value.role2.title"
              label-width="80px"
              prop="name"
            >
              <el-input
                v-model="ruleForm.p7.value.role2.name"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <div style="width: 2px; height: 30px"></div>
          </el-col>
          <el-col :span="1">
            <div style="width: 2px; height: 30px"></div>
          </el-col>
          <el-col :span="1">
            <div style="width: 2px; height: 30px"></div>
          </el-col>
          <el-col :span="11">
            <el-form-item
              :label="ruleForm.p7.value.role3.title"
              label-width="80px"
              prop="name"
            >
              <el-input
                v-model="ruleForm.p7.value.role3.name"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              :label="ruleForm.p7.value.role4.title"
              label-width="80px"
              prop="name"
            >
              <el-input
                v-model="ruleForm.p7.value.role4.name"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <div style="width: 2px; height: 30px"></div>
          </el-col>
          <el-col :span="1">
            <div style="width: 2px; height: 30px"></div>
          </el-col>
          <el-col :span="1">
            <div style="width: 2px; height: 30px"></div>
          </el-col>
          <el-col :span="11">
            <el-form-item
              :label="ruleForm.p7.value.role5.title"
              label-width="80px"
              prop="name"
            >
              <el-input
                v-model="ruleForm.p7.value.role5.name"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>


        <!-- <div class="button_div" style="position: absolute;bottom: 30px;left: 50%;transform: translate(-50%,0);">
            <el-button
              type="primary"
              :disabled="!leader"
              plain
              size="small"
              @click="reInput"
              >上传
            </el-button>
          </div> -->
        
      </div>

      
      <div class="Mini_popup">
        <el-dialog
          title="查看大图"
          :visible.sync="innerVisible"
          width="800px"
          :before-close="handleClose"
          :show-close="false"
        >
          <i class="el-icon-close closei" @click="closeis()"></i>
          <div class="caotu_img">
            <el-button-group>
              <el-button
                type="primary"
                icon="el-icon-arrow-left"
                size="mini"
                @click="prePage"
                >上一张</el-button
              >
              <el-button type="primary" size="mini" @click="nextPage"
                >下一张<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </el-button-group>
            <div style="margintop: 10px; color: #409eff">
              {{ pageNum }} / {{ pageTotalNum }}
            </div>
            <img
              :src="item1.value"
              v-for="(item1, index1) in imgList"
              :key="index1"
              v-show="pageNum == index1 + 1"
              alt=""
            />
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      timer: "",
      countNum: 0,
      count: {},
      imageList: [],
      imgList: [],
      innerVisible: false,
      ruleForm: {
        p1: {},
        p2: {},
        p3: {},
        p4: {},
        p5: {
          value: {
            values: [],
          },
        },
        p6: {},
        p7: {
          value: {
            role1: {},
            role2: {},
            role3: {},
            role4: {},
            role5: {},
          },
        },
      },
      rules: {},
      pageNum: 1,
      pageTotalNum: 1,
      reVideo: false,
      reimg: false,
      leader: "",
      reVideoType: true,
      reimgType: true,
    };
  },
  methods: {
    getapp() {
      this.axios
        .StuCprojectgets9({
          token: this.$store.state.token,
          id: this.$store.state.cla_id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.ruleForm = res.data.data.data;
            this.imageList = this.ruleForm.p1.value;
            this.leader = res.data.data.leader;
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {});
    },
    dk_Dt() {
      this.imgList = this.imageList;
      this.pageTotalNum = this.imgList.length;
      this.innerVisible = true;
    },
    closeis() {
      this.innerVisible = false;
    },
    handleClose(done) {},
    // 上一页
    prePage() {
      let page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
    },

    // 下一页
    nextPage() {
      let page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
    },
    // 获取倒计时
    down() {
      this.axios
        .StudctdGet({
          token: this.$store.state.token,
          id: this.$store.state.cla_id,
        })
        .then((res) => {
          if (res.data.code == 0) {
            if (
              res.data.data != "" &&
              res.data.data != null &&
              res.data.data != "null"
            ) {
              this.countNum = 1;
              this.count = res.data.data;
              this.time = res.data.data.times;
              if (this.count.state == 1) {
                this.countDown();
              } else {
                let h = parseInt((this.time / (60 * 60)) % 24);
                h = h < 10 ? "0" + h : h;
                let m = parseInt((this.time / 60) % 60);
                m = m < 10 ? "0" + m : m;
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s;
                this.timer = m + ":" + s;
                this.timeoutss = setTimeout(this.down, 5000);
              }
            } else {
              this.timeout = setTimeout(this.down, 3000);
              this.countNum = 2;
            }
          } else if (res.data.code == -4054) {
            this.timeout = setTimeout(this.down, 3000);
            this.countNum = 2;
            // this.$message.error(res.data.message)
          } else {
            this.countNum = 2;
          }
        })
        .catch((err) => {});
    },
    //倒计时
    countDown() {
      if (this.time > 0) {
        let h = parseInt((this.time / (60 * 60)) % 24);
        h = h < 10 ? "0" + h : h;
        let m = parseInt((this.time / 60) % 60);
        m = m < 10 ? "0" + m : m;
        let s = parseInt(this.time % 60);
        s = s < 10 ? "0" + s : s;
        this.timer = m + ":" + s;
        this.time--;
        this.timeouts = setTimeout(this.countDown, 1000);
        // setTimeout(this.down,5000)
      } else {
        this.timer = "00" + ":" + "00" + ":" + "00";
      }
    },
    // 重新上传
    emptys() {
      if (this.reVideoType) {
        this.reVideo = true;
      } else {
        this.reVideo = false;
      }
      this.reVideoType = !this.reVideoType;
    },
    handlePreview() {
      this.reVideo = true;
    },
    //上传视频成功后
    handleVideoSuccess(res, file) {
      this.axios
        .StuCprojectsets9({
          token: this.$store.state.token,
          id: this.$store.state.cla_id,
          node: "p8",
          value: res.data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.reVideo = false;
            this.$message.success("上传成功");
            this.getapp();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log("上传失败");
        });
    },
    //验证类型
    beforeAvatarUpload(file) {
      const isimgtype = file.type;
      if (
        isimgtype === "video/avi" ||
        isimgtype === "video/quicktime" ||
        isimgtype === "video/mp4"
      ) {
      } else {
        this.$message.error("上传文件格式不匹配,请重新选择!");
        return false;
      }
    },

    handlePreviewImg() {
      this.reimg = true;
    },
    //上传视频成功后
    handleVideoSuccessImg(res, file) {
      this.axios
        .StuCprojectsets9({
          token: this.$store.state.token,
          id: this.$store.state.cla_id,
          node: "p1",
          value: res.data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.reimg = false;
            this.$message.success("上传成功");
            this.getapp();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log("上传失败", res.data);
        });
    },
    //验证类型
    beforeAvatarUploadImg(file) {
      // const isimgtype = file.type;
      // if (isimgtype === "video/avi" || isimgtype === "video/quicktime" || isimgtype === "video/mp4") {
      // } else {
      //     this.$message.error("上传文件格式不匹配,请重新选择!");
      //     return false;
      // }
    },

    // 重新上传图片
    reImgsss() {
      if (this.reimgType) {
        this.reimg = true;
      } else {
        this.reimg = false;
      }

      this.reimgType = !this.reimgType;
    },
    // 重新上传input
    // reInput(){
    //     this.axios
    //     .StuCprojectsets9({
    //         token: this.$store.state.token,
    //         p2:this.ruleForm.p2.value,
    //         p3:this.ruleForm.p3.value,
    //         p4:this.ruleForm.p4.value,
    //         p6:this.ruleForm.p6.value
    //     }).then(res=> {
    //         console.log(res)
    //     })
    // }
    p2(){
        console.log(111111111111);
         this.reinput('p2',this.ruleForm.p2.value)
    },

    p3(){
        this.reinput('p3',this.ruleForm.p3.value)
    },

    p4(){
        this.reinput('p4',this.ruleForm.p4.value)
    },

    p6(){
        this.reinput('p6',this.ruleForm.p6.value)
    },

    reinput(type,value){
        this.axios
        .StuCprojectsets9({
            node:type,
            token: this.$store.state.token,
            value:value,
            id: this.$store.state.cla_id,
        }).then(res=> {
            console.log(res)
            if (res.data.code == 0) {
           
            this.$message.success("上传成功");
            this.getapp();
          } else {
            this.$message.error(res.data.message);
          }
        })
    }
  },
  beforeDestroy() {
    let that = this;
    clearTimeout(that.timeout);
    clearTimeout(that.timeouts);
    clearInterval(that.scont);
    that.scont = null;
    that.timeout = null;
    that.timeouts = null;
  },
  destroyed() {
    let that = this;
    clearTimeout(that.timeout);
    clearTimeout(that.timeouts);
    clearInterval(that.scont);
    that.scont = null;
    that.timeout = null;
    that.timeouts = null;
  },
  mounted() {
    this.getapp();
    this.down();
    let that = this;
    /*  that.scont = window.setInterval(() => {
            setTimeout(() => {
                that.getapp()
            }, 0)
        },5000) */
  },
};
</script>
<style scoped>
@import "./../../assets/css/student/Minimaering.css";
</style>
<style>
.Mini_popup .el-dialog {
  margin-top: 8vh !important;
}
.Mini_popup .el-dialog__body {
  padding: 20px 0px 10px;
}
.Mini_popup .el-dialog__header {
  padding-top: 10px;
  text-align: center;
  border-bottom: 1px solid #dcdfe6;
  background: #4da3d2;
}
.Mini_popup .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}
.zui_three_div .el-rate__item {
  vertical-align: top;
}
.zui_right .el-textarea.is-disabled .el-textarea__inner {
  color: #333;
  background: #fff;
  cursor: default;
}
.zui_right .el-input.is-disabled .el-input__inner {
  color: #333;
  background: #fff;
  cursor: default;
}
.le_img .el-carousel {
  width: 100%;
  height: 100%;
}
.le_img .el-carousel__container {
  height: 100%;
}
.le_img .el-carousel__arrow {
  display: none !important;
}
.le_img .el-carousel__arrow:hover {
  display: none !important;
}
.le_img .el-carousel__indicators {
  display: none;
}
</style>
